import './App.css';
import React, { useState } from 'react';
import GetBox from './components/GetBox/GetBox';
import PostBox from './components/PostBox/PostBox'

function App() {
  return (
    <div className="App">
      <header>
        <div>Cloudflare App</div>
      </header>
      <main className="App-body">
        <PostBox></PostBox>
        <GetBox></GetBox>
      </main>
    </div>
  );
}

export default App;
