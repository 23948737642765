import React, { Component } from 'react';
import './GetBox.css';

class GetBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errorMessage: ""
    };
  }
  
  componentDidMount() {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json'
      },
    };
    fetch('https://serverless-internship.guptapra.workers.dev/get', requestOptions)
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        this.setState({ data: data })
      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }
  render() {
    return (
      <div className="GetBox">
        
        <div className="get-container">
          {
            Object.entries(this.state.data).map(([key, value]) => (
              <div className="userBox">
                <div>
                  Username: {key}
                </div>
                <div>
                  Title: {JSON.parse(value).title}
                </div>
                <div>
                  Content: {JSON.parse(value).content}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }


};



export default GetBox;
