import React, { Component } from 'react';
import './PostBox.css';

class PostBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: ""
    };
  }

  onSubmit(e) {
    e.preventDefault();
    let bodyMsg = {
      "data": {
        "username": e.target.username.value,
        "title": e.target.title.value,
        "content": e.target.content.value
      }
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyMsg)
    };
    fetch('https://serverless-internship.guptapra.workers.dev/post', requestOptions)
      .then(async response => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        alert(data.message);
        window.location.reload(false);
      })
      .catch(error => {
        //this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }
  render() {
    return (
      <div className="PostBox">
        <form id="post-form" onSubmit={this.onSubmit}>
          <fieldset>
            <label>
              <p>Username</p>
              <input name="username" />
            </label>
            <label>
              <p>Title</p>
              <input name="title" />
            </label>
            <label>
              <p>Content</p>
              <textarea name="content"></textarea>
            </label>
          </fieldset>
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

};


export default PostBox;
